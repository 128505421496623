import { makeAutoObservable, action, computed } from "mobx";

import { ParentStore } from "../ParentStore";
import { parent } from "../../domains/Parent";

export class DonateStore {
  visible = false;
  amount? = 0;
  fundId = "";
  saving = false;
  msg = "";
  store: ParentStore;
  paymentIndex: number | null = null;

  constructor(store: ParentStore) {
    makeAutoObservable(this, {
      store: false,
      payment: computed,
    });
    this.store = store;
  }

  get payment(): any {
    return this.paymentIndex == null ? null : parent.payMethods[this.paymentIndex] || null;
  }

  @action show(fundId, amount) {
    this.fundId = fundId;
    this.amount = amount;
    if (parent.payMethods.length > 0) {
      this.paymentIndex = parent.payMethods.findIndex((x: any) => x.isDefault) || 0;
    } else {
      this.paymentIndex = null;
    }
    this.visible = true;
  }

  @action close() {
    this.visible = false;
  }

  @action setPayIndex(index) {
    this.paymentIndex = index;
  }

  @action setMsg(msg) {
    this.msg = msg;
  }
}
