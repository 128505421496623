import { makeAutoObservable, runInAction, action } from "mobx";
import { Api } from "../common/Api";

export class DonateInviteStore {
  id: string = "";
  amount? = 0;
  loading = false;
  saving = false;
  inviteInfo: any;
  msg = "";
  state: "Pending" | "Success" | "Error" = "Pending";

  constructor() {
    makeAutoObservable(this, {});
  }

  @action
  loadInvite(id) {
    this.state = "Pending";
    this.inviteInfo = null;
    this.loading = true;
    this.id = id;
    Api.donateInvite
      .get(id)
      .then((res) => {
        runInAction(() => {
          this.inviteInfo = res.data.data;
        });
      })
      .finally(() => (this.loading = false));
  }

  @action
  retry() {
    this.state = "Pending";
  }

  @action setMsg(msg) {
    this.msg = msg;
  }

  @action setStste(state) {
    this.state = state;
  }
}

export const donateInviteStore = new DonateInviteStore();
