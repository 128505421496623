import React from "react";
import { Modal, Form, Input, Rate } from "antd";

export const FeedbackDlg = ({ onClose }) => {
  const [form] = Form.useForm();

  const save = () => {
    form.validateFields().then((values) => {});
  };

  return (
    <Modal
      title="We value your input!"
      visible={true}
      maskClosable={false}
      onOk={save}
      okText="Submit"
      // confirmLoading={saving}
      onCancel={() => onClose()}
      width={648}
      getContainer={false}
    >
      <Form form={form} initialValues={{}}>
        <Form.Item name="rate" style={{ textAlign: "center" }}>
          <Rate style={{ fontSize: "60px" }} />
        </Form.Item>

        <Form.Item name="content">
          <Input.TextArea rows={8} maxLength={500} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
