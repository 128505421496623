import React, { useEffect } from "react";
import "./Donate.scss";
import { observer } from "mobx-react-lite";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { DonateForm } from "./DonateForm";
import { useParams } from "react-router-dom";
import { donateInviteStore } from "../../store/DonateInviteStore";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");

export const DonatePage = observer(() => {
  let { id } = useParams<any>();

  useEffect(() => {
    donateInviteStore.loadInvite(id);
    document.body.classList.toggle("donate-bg", true);
    return () => {
      document.body.classList.toggle("donate-bg", false);
    };
  }, []);

  useEffect(() => {
    donateInviteStore.loadInvite(id);
  }, [id]);

  return (
    <div className="donate-page">
      <Elements stripe={stripePromise}>
        <DonateForm></DonateForm>
      </Elements>
    </div>
  );
});
