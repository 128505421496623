import React from "react";
import "./Admin.scss";
import { FrCard } from "../../components/FrCard";
import { fmtMoney } from "../../common/Utils";
import { observer } from "mobx-react";

export const Top10 = observer(({ donors = [] }: { donors: any[] }) => {
  return (
    <FrCard title="Top 10 Donor Families" style={{ minHeight: 400 }}>
      <table className="top10">
        <tbody>
          {donors.map((s: any, i) => (
            <tr key={i}>
              <th>
                {s.meta?.userName} {s.meta?.children ? `(${s.meta.children})` : ""}
              </th>
              <td>{fmtMoney(s.total / 100.0)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </FrCard>
  );
});
