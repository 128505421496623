import { Button, Popover } from "antd";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { SchoolsBar } from "../../../components/SchoolsBar";
import { StudentDlg } from "../../../components/StudentDlg";
import { appStore } from "../../../store/AppStore";
import { parent } from "../../../store/domains/Parent";
import "./ParentSchoolsBar.scss";

export const ParentSchoolsBar = observer(({ btnText, onBtn }: any) => {
  const onSchool = (id) => {
    appStore.parent.fundraisingsStore.setActiveSchool(id);
  };

  const onAdd = () => appStore.parent.editChild.edit();

  return (
    <SchoolsBar
      schools={parent.schools}
      curSchoolId={appStore.parent.fundraisingsStore.activeSchoolId}
      onSchool={onSchool}
      content="Add another school."
      showAdd
      onBtn={() => onAdd()}
    >
      <StudentDlg />
    </SchoolsBar>
  );
});
