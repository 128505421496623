import React, { useState } from "react";
import { Form, Input, Modal } from "antd";
import CardSection from "./CardSection";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { meGql } from "../../graphql/me";
import { useMutation } from "@apollo/react-hooks";
import { showSuccess } from "../../common/Utils";

export const AddCardDlg = ({ onCancel, onDone }) => {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [setupSecret] = useMutation(meGql.setupSecret);
  const [msg, setMsg] = useState("");

  const onChange = (e) => {
    console.log("onChange", e);
    setMsg("");
  };
  const onDonate = async () => {
    if (!stripe || !elements) {
      return;
    }

    form.validateFields().then(async (values) => {
      setSaving(true);
      const {
        data: {
          setupSecret: { clientSecret },
        },
      }: any = await setupSecret();

      const data = {
        payment_method: { card: elements?.getElement(CardElement) as any, billing_details: values.billingDetails },
      };
      const result = await stripe.confirmCardSetup(clientSecret, data);
      setSaving(false);
      if (result.error) {
        console.log(result.error.message);
        setMsg("Cannot process your payment. Please check the information or try another card.");
      } else {
        if (result?.setupIntent?.status === "succeeded") {
          showSuccess();
          onDone();
        }
      }
    });
  };

  return (
    <Modal
      visible={true}
      onCancel={onCancel}
      onOk={onDonate}
      title="Add a Card"
      className="DonateDlg"
      confirmLoading={saving}
      width={600}
      centered
      maskClosable={false}
      bodyStyle={{ padding: "20px 24px 32px 24px" }}
    >
      <Form className="strip-form" form={form} layout="vertical">
        <Form.Item label="Card Holder Name" name={["billingDetails", "name"]} rules={[{ required: true }]}>
          <Input></Input>
        </Form.Item>
        <Form.Item label="Credit or Debit Card" required help={msg} validateStatus={msg ? "error" : "success"}>
          <CardSection onChange={onChange} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
