import React from "react";
import "./Account.scss";
import { observer } from "mobx-react-lite";
import { Form, Input, Button, Statistic, Row, Col } from "antd";
import { FrCard } from "../../components/FrCard";
import { appStore } from "../../store/AppStore";
import { Utils } from "../../common/Utils";

const { Countdown } = Statistic;

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

export const ChangeEmail = observer(() => {
  const store = appStore.changeEmail;
  const [form] = Form.useForm();
  store.form = form;

  const onValuesChange = (values) => {
    if (Utils.hasKey(values, "emailNew")) {
      setTimeout(() => {
        if (form.getFieldError("emailNew").length === 0) {
          store.email = values.emailNew;
        } else {
          store.email = "";
        }
      });
    } else if (Utils.hasKey(values, "code")) {
      store.code = values.code;
    } else if (Utils.hasKey(values, "pwd")) {
      store.pwd = values.pwd;
    }
  };

  const onSave = () => {
    form.validateFields().then((values) => {
      store.submit();
    });
  };

  return (
    <FrCard title="Change Email" style={{ paddingBottom: 1 }}>
      <Form
        form={form}
        onValuesChange={onValuesChange}
        style={{ marginLeft: 16, marginRight: 16 }}
        {...layout}
        labelAlign="left"
        name="basic"
      >
        <Form.Item style={{ width: "100%" }} label="Current Email Address">
          {store.curEmail}
        </Form.Item>
        <Form.Item
          style={{ width: "100%" }}
          label="Current Password"
          name="pwd"
          rules={[{ required: true, message: "Please input your password." }]}
        >
          <Input.Password autoComplete="new-password" />
        </Form.Item>
        <Form.Item
          style={{ width: "100%" }}
          label="New Email Address"
          name="emailNew"
          rules={[
            { required: true, message: "Invalid email address." },
            {
              type: "email",
              message: "New email address is not a valid email.",
            },
          ]}
        >
          <Input style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Confirmation Code"
          name="code"
          rules={[{ required: true, message: "Please input confirmation code." }]}
        >
          <Row gutter={8}>
            <Col span={16}>
              <Input style={{ width: "100%" }} />
            </Col>
            <Col span={8}>
              <Button
                block
                onClick={() => store.sendCode()}
                type={store.canSendCode && !store.isCountDown ? "primary" : "default"}
              >
                {store.isCountDown ? (
                  <Countdown
                    valueStyle={{ fontSize: "14px" }}
                    value={Date.now() + 1000 * 60 * 5}
                    format="s"
                    onFinish={() => store.countDownFinish()}
                  />
                ) : (
                  <span>{store.isResend ? "Resend" : "Send Code"}</span>
                )}
              </Button>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item {...tailLayout} style={{ textAlign: "center" }}>
          <Button loading={store.saving} type={store.canSubmit ? "primary" : "default"} onClick={() => onSave()}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </FrCard>
  );
});
