import { makeAutoObservable, action, computed, runInAction } from "mobx";
import { Api } from "../../../common/Api";
import { showSuccess, defaultQLError } from "../../../common/Utils";
import { TeacherStore } from "../TeacherStore";

export class ChallengeStore {
  activeSchoolId;

  store: TeacherStore;

  id;
  loading = false;
  showDetail = false;
  showWork = false;
  student;
  engActivity: any = { participant: {}, students: [] };
  showType: null | number = null;

  constructor(store: TeacherStore) {
    makeAutoObservable(this, {
      store: false,
      stats: computed,
      students: computed,
      title: computed,
      options: computed,
    });
    this.store = store;
  }

  get stats() {
    const { students } = this.engActivity;
    const total = students.length;
    const notStarted = parseInt(((students.filter((x) => x.status === 0).length * 100) / total).toFixed(0));
    const inProgress = parseInt(((students.filter((x) => x.status === 1).length * 100) / total).toFixed(0));
    const finished = 100 - notStarted - inProgress;
    return [
      { name: "Not Started", value: notStarted, selected: this.showType === 0, status: 0 },
      { name: "In Progress", value: inProgress, selected: this.showType === 1, status: 1 },
      { name: "Finished", value: finished, selected: this.showType === 2, status: 2 },
    ];
  }

  get students() {
    if (this.showType === null) {
      return this.engActivity.students;
    }
    return this.engActivity.students.filter((x) => x.status === this.showType);
  }

  get title() {
    const { students } = this.engActivity;
    const total = students.length;
    const notStarted = parseInt(((students.filter((x) => x.status === 0).length * 100) / total).toFixed(0));
    const inProgress = parseInt(((students.filter((x) => x.status === 1).length * 100) / total).toFixed(0));
    const finished = 100 - notStarted - inProgress;

    if (this.showType === null) {
      return "All Students";
    } else if (this.showType === 0) {
      return `${notStarted}% of students have not started`;
    } else if (this.showType === 1) {
      return `${inProgress}% of students are in progress`;
    } else if (this.showType === 2) {
      return `${finished}% of students have finished`;
    }
  }

  get options() {
    return {
      color: ["#0CBEFF", "#7671FF", "#FFD150"],
      series: [
        {
          data: this.stats,
          type: "pie",
          radius: "70%",
          selectedMode: "single",
          label: {
            show: false,
          },
        },
      ],
      tooltip: {
        trigger: "item",
        formatter: `{b}:  {d}%`,
      },
    };
  }
  @action load(id: string) {
    this.id = id;
    Api.teacher.engActivity(id).then((res) => {
      runInAction(() => {
        this.engActivity = res.data.engActivity;
      });
    });
  }

  @action refetch() {
    this.load(this.id);
  }

  @action setShowType(type) {
    if (this.showType === type) {
      this.showType = null;
    } else {
      this.showType = type;
    }
  }

  @action onComplete() {
    if (!this.engActivity.canComplete) return;

    Api.teacher
      .completeEngActivity(this.id)
      .then((res) => {
        showSuccess();
        this.refetch();
      })
      .catch((err) => defaultQLError(err));
  }

  @action onRemind(status) {
    Api.teacher
      .remindEngActivity(this.id, status)
      .then((res) => {
        showSuccess();
      })
      .catch((err) => defaultQLError(err));
  }

  @action onStudent(student) {
    if (student.workId) {
      this.student = student;
      this.showWork = true;
    }
  }

  @action hideWork() {
    this.showWork = false;
    if (this.student?.hasMsg) this.refetch();
  }
}
