import React from "react";
import "./Account.scss";
import { Row, Col } from "antd";
import { ChangeEmail } from "./ChangeEmail";
import { Students } from "./Students";
import { Donates } from "./Donates";
import { Payments } from "./Payments";
import { observer } from "mobx-react";
import { appStore } from "../../store/AppStore";
import { parent } from "../../store/domains/Parent";

export const Account = observer(() => {
  return (
    <div className="account body-wrapper">
      <Row gutter={15}>
        <Col sm={{ span: 20, offset: 2 }} md={{ span: 16, offset: 4 }} lg={{ span: 12, offset: 6 }} xs={24}>
          {appStore.me.isParent && <Students />}
          {appStore.me.isParent && <Payments />}
          {appStore.me.isParent && parent.donates.length > 0 && <Donates />}
          <ChangeEmail />
        </Col>
      </Row>
    </div>
  );
});
