import { observer } from "mobx-react";
import React from "react";
import { SchoolsBar } from "../../../components/SchoolsBar";
import { appStore } from "../../../store/AppStore";
import { fundraiser } from "../../../store/domains/Fundraiser";

export const FundSchoolsBar = observer(({ btnText, onBtn }: any) => {
  const onSchool = (id) => {
    appStore.admin.schoolsStore.setActiveSchool(id);
  };

  return (
    <SchoolsBar
      schools={fundraiser.schools}
      curSchoolId={appStore.admin.schoolsStore.activeSchoolId}
      btnText={btnText}
      onBtn={onBtn}
      onSchool={onSchool}
    ></SchoolsBar>
  );
});
