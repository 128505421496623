import React, { useState } from "react";
import "./WorkDlg.scss";
import { Modal, Input, Button, Typography } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { engGql } from "../../../graphql/engagement";
import { useEffect } from "react";
import { Api } from "../../../common/Api";
import { useRef } from "react";

export const WorkDlg = ({ challengeId, student, onClose }) => {
  console.log("student work", student);
  const messagesEndRef = useRef<any>(null);
  const [msg, setMsg] = useState("");
  const [sending, setSending] = useState(false);
  const { data: { challengeWork } = { challengeWork: { Content: [], comments: [] } }, refetch } = useQuery<any>(
    engGql.challengeWork,
    {
      variables: { challengeId, workId: student.workId || "none" },
    }
  );

  useEffect(() => {
    refetch();
  }, [student, refetch]);

  const onInput = (e) => {
    setMsg(e.target.value);
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(scrollToBottom, [challengeWork.comments]);

  const onSend = () => {
    if (!student.workId || !msg.trim()) return;
    setSending(true);
    Api.post
      .sendComment(student.workId, msg.trim())
      .then((res) => {
        setMsg("");
        refetch();
      })
      .finally(() => setSending(false));
  };

  return (
    <Modal
      className="WorkDlg"
      title={`${student.child.name}’s Challenge`}
      visible={true}
      maskClosable={false}
      onOk={onClose}
      // confirmLoading={saving}
      onCancel={() => onClose()}
      width={520}
      getContainer={false}
      footer={null}
      bodyStyle={{ padding: "12px 40px 20px" }}
    >
      <div className="post-box">
        <div className="post-title">{challengeWork.Title}</div>
        {challengeWork.Content.map((x, i) => (
          <div key={i + ""} className="post-section">
            {x.sectionType === "text" && <div>{x.sectionContent}</div>}{" "}
            {x.sectionType === "image" && (
              <img src={`https://${process.env.REACT_APP_CLOUDFRONT}.cloudfront.net/${x.sectionContent}`} />
            )}
            {x.sectionType === "video" && (
              <video
                controls={true}
                preload="preload"
                src={`https://${process.env.REACT_APP_CLOUDFRONT}.cloudfront.net/${x.sectionContent}`}
              />
            )}
          </div>
        ))}
      </div>
      <div className="commnets-box">
        {challengeWork.comments.map((x) => (
          <Typography.Paragraph key={x.CommentId}>
            <Typography.Text strong>{x.userName}: </Typography.Text> {x.Comment}
          </Typography.Paragraph>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="input-box">
        <Input value={msg} onChange={onInput} placeholder="Comment (Only this parent can see)"></Input>
        <Button disabled={!msg.trim()} type="primary" onClick={onSend} loading={sending}>
          Send
        </Button>
      </div>
    </Modal>
  );
};
