import { makeAutoObservable, action } from "mobx";
import { AppStore } from "../AppStore";
import { EditChallengeStore } from "./challenge/EditChallengeStore";
import { teacher } from "../domains/Teacher";
import { TeachSchoolsStore } from "./challenge/TeachSchoolsStore";
import { ChallengeStore } from "./challenge/ChallengeStore";

export class TeacherStore {
  store: AppStore;
  schoolsStore: TeachSchoolsStore;
  challenge: EditChallengeStore;
  challengeDetails: ChallengeStore;

  constructor(store) {
    makeAutoObservable(this, {
      store: false,
    });
    this.store = store;
    this.challenge = new EditChallengeStore(this);
    this.challengeDetails = new ChallengeStore(this);
    this.schoolsStore = new TeachSchoolsStore(this);
  }

  @action
  async loadParticipantsAndSelectGroup(orgId, classId) {
    const school = teacher.schools.find((x) => x.id === orgId);
    if (!school) return;

    await school.loadParticipants();
    const participants = school.participants || [];
    const group = participants.find((x) => x.typeId === classId);
    this.challenge.participant = group;
    this.challenge.form?.setFieldsValue({ participantId: group!.type + group!.typeId });
  }

  @action
  update({ schools = [] }) {
    teacher.update({ schools });
    this.schoolsStore.checkActiveSchoolId();
  }
}
