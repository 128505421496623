import { makeAutoObservable, autorun, runInAction, action } from "mobx";

export class SchoolParticipant {
  typeId = "";
  type = "";
  title = "";
  grades = [];
  count = 0;

  constructor({ typeId = "", type = "", title = "", grades = [], count = 0 }: Partial<SchoolParticipant> = {}) {
    makeAutoObservable(this);
    this.typeId = typeId;
    this.type = type;
    this.title = title;
    this.grades = grades;
    this.count = count;
  }
}
