import React from "react";
import { Typography } from "antd";
import "./engagement.scss";

export const FinishPanel = () => {
  return (
    <div className="finish-panel">
      <Typography.Title level={4} style={{ textAlign: "center" }}>
        You are almost there!
      </Typography.Title>

      <Typography.Paragraph>
        After you submit this Challenge Event, you will not be able to make any changes. So please ensure all the
        information you have provided is correct. On the morning of the Event start date, an email will be sent to
        participants' parents. If a parent has downloaded the FooMoo app, he/she will receive an App notification.
        Parents can read the Challenge Event instruction and participate via the App.
      </Typography.Paragraph>
      <Typography.Paragraph>
        You will be able to monitor the Event progress on this site under the Challenge Event / Event List tab through
        each Event's Detail page. If there is work submission, you can also view students' submitted work and provide
        private comments on the Detail page or via the FooMoo App. You can log into the FooMoo app using the same email
        and password for this website. Parents may view other students' work once they have completed submission for
        their children.
      </Typography.Paragraph>
      <Typography.Paragraph>
        We would genuinely appreciate feedback on your experience. You can either email us at{" "}
        <a href="mailto:help@foo-moo.com">help@foo-moo.com</a> or send it on the FooMoo App at any time.
      </Typography.Paragraph>
      <Typography.Paragraph>Thank you and we wish a very successful and fun Event!</Typography.Paragraph>
      <Typography.Paragraph>
        Your Friends <strong>@FooMoo</strong>
      </Typography.Paragraph>
    </div>
  );
};
