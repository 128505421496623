const validInfo = ({ participantId = "", type = "", name = "", customName = "", description = "" }): boolean => {
  if (name === "-" && !customName.trim()) return false;

  return !!participantId && !!type && !!name && !!description;
};

const validGoal = ({ enabled = true, description = "" } = {}): boolean => {
  return enabled === false || !!description.trim();
};

const validWorkSubmission = ({ enabled = true, description = "" } = {}): boolean => {
  return enabled === false || !!description.trim();
};

const validNext = (curStep, values) => {
  console.log("v", values);
  if (curStep === 0) {
    return validInfo(values);
  } else if (curStep === 1) {
    return validGoal(values.goal);
  } else if (curStep === 2) {
    return validWorkSubmission(values.workSubmission);
  }
  return true;
};

export const EngDlgUtils = {
  validNext,
};
