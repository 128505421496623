import { makeAutoObservable, autorun, runInAction, action } from "mobx";
import { Me } from "./Me";
import Auth from "@aws-amplify/auth";
import { TeacherStore } from "./teacher/TeacherStore";
import { Api } from "../common/Api";
import { ParentStore } from "./parent/ParentStore";
import { AdminStore } from "./admin/AdminStore";
import { InviteStore } from "./InviteStore";
import { myHistory } from "../common/History";
import { client } from "../graphql/client";
import { ChangeEmailStore } from "./ChangeEmailStore";
import { parent } from "./domains/Parent";

export class AppStore {
  isLogin = false;
  me: Me;
  teach: TeacherStore;
  parent: ParentStore;
  admin: AdminStore;
  inviteStore: InviteStore;
  changeEmail: ChangeEmailStore;

  constructor() {
    makeAutoObservable(this);
    this.me = new Me(this);
    this.teach = new TeacherStore(this);
    this.parent = new ParentStore(this);
    this.admin = new AdminStore(this);
    this.inviteStore = new InviteStore(this);
    this.changeEmail = new ChangeEmailStore(this);
    console.log("appstore init");

    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then((user) => {
        console.log("here", user);
        this.isLogin = true;
        Auth.userAttributes(user)
          .then(async (res) => {
            await client.cache.reset();
            runInAction(() => {
              console.log("is login", true);
              if (this.isLogin) {
                this.loadMe();
              }
            });
          })
          .catch((err) => {
            console.log("dd", err);
            this.isLogin = false;
            if (err.code === '"PasswordResetRequiredException"') {
              myHistory.push("/reset");
            }
          });
      })
      .catch((err) => {
        const { pathname = "" } = myHistory.location;
        if (
          !pathname.startsWith("/invite/") &&
          !pathname.startsWith("/donate/") &&
          !pathname.startsWith("/d/") &&
          !["/login", "/contact", "/how", "/about"].includes(pathname)
        ) {
          myHistory.push("/");
        }
      });
  }

  @action
  loadMe() {
    Api.me().then((data) => {
      console.log(data, "me");
      const { teach, admin, isParent } = data.data.me;
      runInAction(() => {
        this.me.update(data.data.me);
        this.teach.update(teach || {});
        if (isParent) {
          this.parent.update(data.data.me.parent || {});
          parent.loadPayMethods();
        }
        this.admin.update(admin || {});

        // TODO: 如果多个学校，判断默认显示学校
        if (myHistory.location.pathname === "/") {
          if (this.me.isParent) {
            myHistory.push("/fundraiser");
          }
        }
      });
    });
  }

  @action
  logout = () => {
    return Auth.signOut().then(async () => {
      await client.cache.reset();
      runInAction(() => {
        this.isLogin = false;
      });
    });
  };
}

export const appStore = new AppStore();
