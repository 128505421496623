import { makeAutoObservable, autorun, runInAction, action } from "mobx";
import { Api } from "../common/Api";

export class Me {
  store = null;
  isTeacher = true;
  isParent = false;
  isAdmin = false;
  name = "";
  email = "";
  firstName = "";

  parent?: {
    children: any[];
    schools: any[];
  };

  teach?: {
    schools: any[];
  };

  constructor(store) {
    makeAutoObservable(this, {
      store: false,
    });
    this.store = store;
  }

  @action
  update({ name, email, firstName, isTeacher, isParent, isAdmin }) {
    runInAction(() => {
      this.name = name;
      this.email = email;
      this.firstName = firstName;
      this.isTeacher = isTeacher;
      this.isParent = isParent;
      this.isAdmin = isAdmin;
    });
  }
}
