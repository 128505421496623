import React from "react";
import { Row, Col, Layout, Typography } from "antd";
import { FriendsDonation } from "./components/FriendsDonation";
import { Fundraiser } from "../../fundraiser/Fundraiser";
import { Goals } from "../../fundraiser/Goals";
import { YourDonation } from "./components/YourDonation";
import { observer } from "mobx-react";
import { appStore } from "../../../store/AppStore";
import { FrCard } from "../../../components/FrCard";

export const ParentFundraiser = observer(() => {
  const { activeFundId, fundraisers, activeFund } = appStore.parent.fundraisingsStore;
  return (
    <Layout.Content>
      <div className="container " style={{ marginTop: 20 }}>
        {activeFund ? (
          <>
            <Row gutter={20}>
              <Col xs={24} sm={12} style={{ marginBottom: 20 }}>
                <Fundraiser
                  fund={activeFund}
                  curFund={activeFundId}
                  fundraisers={fundraisers}
                  onChange={(val) => {
                    appStore.parent.fundraisingsStore.setActiveFund(val);
                  }}
                />
              </Col>
              <Col xs={24} sm={12} style={{ marginBottom: 20 }}>
                <YourDonation
                  fundId={activeFund.id}
                  stats={activeFund.userStats}
                  end={activeFund.end}
                  donateCount={activeFund.donateCount}
                  historic={activeFund.historic}
                  suggested={activeFund.suggested}
                />
                {activeFund.type === 0 && <FriendsDonation />}
              </Col>
            </Row>
            {activeFund.type > 0 && (
              <Row gutter={20} style={{ marginTop: 20, marginBottom: 40 }}>
                <Col xs={24} sm={9} style={{ marginBottom: 20 }}>
                  <FriendsDonation style={{ height: "100%", marginTop: 0 }} />
                </Col>
                <Col xs={24} sm={15} style={{ marginBottom: 20 }}>
                  <Goals fund={activeFund} />
                </Col>
              </Row>
            )}
          </>
        ) : (
          <FrCard>
            <div className="fcc" style={{ minHeight: 300, flexDirection: "column" }}>
              <Typography.Paragraph style={{ marginBottom: 48, fontSize: 48, textAlign: "center" }}>
                PLEASE COME BACK LATER.
              </Typography.Paragraph>
              <Typography.Paragraph style={{ marginBottom: 64, fontSize: 24, textAlign: "center" }}>
                No Active Fundraiser in Progress.
              </Typography.Paragraph>
            </div>
          </FrCard>
        )}
      </div>
    </Layout.Content>
  );
});
