import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Layout, Card, Space, Button, Col, Descriptions, Row, Badge } from "antd";
import "./challenge.scss";
import {
  MailOutlined,
  TeamOutlined,
  ClockCircleOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  UnorderedListOutlined,
  FlagOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { timeDuration } from "../../../common/Utils";
import { WorkDlg } from "../components/WorkDlg";
import { observer } from "mobx-react";
import { appStore } from "../../../store/AppStore";

import ReactECharts from "echarts-for-react";

export const Event = observer(() => {
  const store = appStore.teach.challengeDetails;
  let { id } = useParams<any>();
  const history = useHistory();

  useEffect(() => {
    store.load(id);
    setInterval(() => store.refetch(), 60 * 1000);
  }, []);

  const onBack = () => {
    history.push("/teach/events");
  };

  return (
    <Layout className="teach">
      <Layout.Content style={{ marginTop: 24, paddingBottom: 24 }}>
        <div className="container">
          {store.engActivity.id && (
            <Card style={{ marginTop: 15 }} className="event-card" bodyStyle={{ padding: 0 }}>
              <div className="card-title">
                {store.engActivity.customName || store.engActivity.name}
                <Button
                  onClick={onBack}
                  icon={<CloseOutlined></CloseOutlined>}
                  type="link"
                  className="close-btn ant-modal-close"
                ></Button>
              </div>
              <div className="card-bar">
                <div className="card-bar-handler" onClick={() => (store.showDetail = !store.showDetail)}>
                  <Descriptions>
                    <Descriptions.Item
                      span={1}
                      label={
                        <Space>
                          <TeamOutlined /> Participants
                        </Space>
                      }
                    >
                      {store.engActivity.participant.title}
                    </Descriptions.Item>
                    <Descriptions.Item
                      span={2}
                      label={
                        <Space>
                          <ClockCircleOutlined />
                          Time Duration
                        </Space>
                      }
                    >
                      {timeDuration(store.engActivity)}
                    </Descriptions.Item>
                  </Descriptions>
                  {store.showDetail ? (
                    <CaretUpOutlined style={{ fontSize: "32px" }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: "32px" }} />
                  )}
                  {store.showDetail && (
                    <div className="event-info">
                      <div className="descriptions-item">
                        <div className="descriptions-item-label">
                          <Space>
                            <MailOutlined /> Invitation
                          </Space>
                        </div>
                        <div
                          className="descriptions-item-content"
                          style={{
                            color: "#828587",
                          }}
                        >
                          <div>Parents,</div>
                          {store.engActivity.description}
                        </div>
                      </div>

                      {store.engActivity.goal.enabled && (
                        <div className="descriptions-item">
                          <div className="descriptions-item-label">
                            <Space>
                              <FlagOutlined /> Challenge Goals
                            </Space>
                          </div>
                          <div className="descriptions-item-content">
                            <div>{store.engActivity.goal.description}</div>
                            {store.engActivity.goal.items.map((x, i) => (
                              <div key={i + ""}>- {x}</div>
                            ))}
                          </div>
                        </div>
                      )}

                      {store.engActivity.workSubmission.enabled && (
                        <div className="descriptions-item">
                          <div className="descriptions-item-label">
                            <Space>
                              <UnorderedListOutlined />
                              Work Submission
                            </Space>
                          </div>
                          <div className="descriptions-item-content">
                            {store.engActivity.workSubmission.items.map((x, i) => (
                              <div key={i + ""}>{x}</div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <Button
                  size="large"
                  type="primary"
                  disabled={!store.engActivity.canComplete}
                  onClick={() => store.onComplete()}
                >
                  Complete
                </Button>
              </div>
              <div className="event-body">
                <Row>
                  <Col span={10}>
                    <ReactECharts
                      style={{ height: 400 }}
                      option={store.options}
                      onEvents={{
                        click: (params) => {
                          store.setShowType(params.dataIndex);
                        },
                      }}
                    />
                  </Col>
                  <Col span={14}>
                    <Card
                      title={<Badge dot={store.students.filter((x) => x.hasMsg).length > 0}>{store.title}</Badge>}
                      headStyle={{ textAlign: "center", borderBottom: "none" }}
                      bodyStyle={{ overflow: "auto", height: 336, padding: 0 }}
                    >
                      <Row>
                        {store.students.map((s) => (
                          <Col key={s.id} span={12}>
                            <Badge dot={s.hasMsg}>
                              {s.workId ? (
                                <a
                                  style={{ paddingLeft: 24, height: 48, display: "block" }}
                                  onClick={() => store.onStudent(s)}
                                >
                                  {s.child?.name}
                                </a>
                              ) : (
                                <div style={{ paddingLeft: 24, height: 48 }}>{s.child?.name}</div>
                              )}
                            </Badge>
                          </Col>
                        ))}
                      </Row>
                      {store.showType === 0 && (
                        <div className="card-footer" style={{ textAlign: "center" }}>
                          <Button style={{ width: 282 }} type="primary" onClick={() => store.onRemind(0)}>
                            Send Participation Reminder
                          </Button>
                        </div>
                      )}
                      {store.showType === 1 && (
                        <div className="card-footer" style={{ textAlign: "center" }}>
                          <Button style={{ width: 282 }} type="primary" onClick={() => store.onRemind(1)}>
                            Send Deadline Reminder
                          </Button>
                        </div>
                      )}
                    </Card>
                    {store.showType === 2 && (
                      <div className="card-footer-tip">
                        Click on names of students who have finished to see their submitted work. You can also send
                        private messages to their parents to encourage these students’ work.
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </Card>
          )}
          {store.showWork && store.student && (
            <WorkDlg challengeId={id} student={store.student} onClose={() => store.hideWork()}></WorkDlg>
          )}
        </div>
      </Layout.Content>
    </Layout>
  );
});
