import { action, computed, makeAutoObservable, observable } from "mobx";
import moment from "moment";
import { parent } from "../../domains/Parent";
import { ParentStore } from "../ParentStore";

export class ParentFundraisingsStore {
  activeSchoolId;
  activeFundIds = observable({});
  store: ParentStore;

  constructor(store: ParentStore) {
    makeAutoObservable(this, {
      store: false,
      fundraisers: computed,
      activeFundId: computed,
      activeFund: computed,
    });
    this.store = store;
  }

  get fundraisers(): any[] {
    return parent.schools.find((x) => x.id === this.activeSchoolId)?.fundraisings || [];
  }

  get activeFundId() {
    return this.activeFundIds[this.activeSchoolId];
  }

  get activeFund() {
    return this.fundraisers.find((x) => x.id === this.activeFundId);
  }

  @action
  setActiveSchool(id) {
    this.activeSchoolId = id;
    if (!this.activeFundId && this.fundraisers.length > 0) {
      this.setActiveFund(this.fundraisers[0].id);
    }
  }

  @action
  setActiveFund(id) {
    this.activeFundIds[this.activeSchoolId] = id;
  }

  @action
  checkActiveSchoolId() {
    if (parent.schools.length === 0) {
      this.setActiveSchool(null);
    } else if (parent.schools.length === 1) {
      this.setActiveSchool(parent.schools[0].id);
    } else {
      let notDonateSchools = parent.schools
        .filter((x) => x.fundraisings.length > 0)
        .filter((x) => x.fundraisings[0].userStats.userDonated === 0);

      if (notDonateSchools.length > 0) {
        notDonateSchools = notDonateSchools.sort(
          (a, b) => moment(a.fundraisings[0].end).valueOf() - moment(b.fundraisings[0].end).valueOf()
        );
        this.setActiveSchool(notDonateSchools[0].id);
        this.setActiveFund(notDonateSchools[0].fundraisings[0].id);
        return;
      }

      let schools = parent.schools
        .filter((x) => x.fundraisings.length > 0)
        .sort((a, b) => moment(a.fundraisings[0].end).valueOf() - moment(b.fundraisings[0].end).valueOf());
      if (schools.length > 0) {
        this.setActiveSchool(schools[0].id);
        this.setActiveFund(schools[0].fundraisings[0].id);
      }
    }
  }
}
