import { action, makeAutoObservable, runInAction } from "mobx";
import { Api } from "../../common/Api";

export class Class {
  classId = null;
  orgId = null;
  name = "";
  students: any[] = [];

  constructor({ orgId, classId, name, students = [] }) {
    makeAutoObservable(this);
    this.orgId = orgId;
    this.classId = classId;
    this.name = name;
    this.students = students;
  }

  @action
  loadStudents() {
    const { classId } = this;

    return Api.teacher.students({ classId }).then((data) => {
      runInAction(() => {
        this.students = data.data.students;
      });
    });
  }
}
