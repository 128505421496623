import { FormInstance } from "antd/lib/form";
import { makeAutoObservable, computed, runInAction } from "mobx";
import { Api } from "../../../common/Api";
import { Class } from "../../domains/Class";
import { TeachSchoolsStore } from "../challenge/TeachSchoolsStore";
import { teacher } from "../../domains/Teacher";

export class EditGroupStore {
  id;
  name = "";
  keyword = "";
  orgId;
  selected: string[] = [];
  store: TeachSchoolsStore;
  visible = false;
  form?: FormInstance;
  saving = false;
  create4challenge = false;

  constructor(store: TeachSchoolsStore) {
    makeAutoObservable(this, {
      store: false,
      school: computed,
      grades: computed,
      schoolStudents: computed,
      schoolAllStudents: computed,
    });
    this.store = store;
  }

  get school() {
    return teacher.schools.find((x) => x.id === this.orgId);
  }

  get grades() {
    return this.school?.grades || [];
  }

  get schoolAllStudents() {
    return this.school?.students || [];
  }

  get schoolStudents() {
    const kw = this.keyword.trim();
    if (kw) {
      return this.school?.students.filter((x) => x.name.toLocaleLowerCase().includes(kw.toLocaleLowerCase())) || [];
    }
    return this.school?.students || [];
  }

  show(create4challenge = false, orgId = null) {
    this.form?.setFieldsValue({ name: "" });
    this.id = null;
    this.orgId = orgId || this.store.activeSchoolId;
    this.name = "";
    this.create4challenge = create4challenge;
    this.selected = [];
    this.visible = true;
    this.school?.loadClasses();
    this.school?.loadStudents();
  }
  edit(it) {
    this.id = it.classId;
    this.orgId = this.store.activeSchoolId;
    this.name = it.name;
    this.create4challenge = false;
    this.selected = it.students.map((x: any) => x.childId);
    console.log("form", this.form, it);
    this.form?.setFieldsValue(it);
    this.visible = true;
  }
  save() {
    this.saving = true;
    const allChildIds = this.schoolAllStudents.map((x) => x.childId);
    let checkedItems = this.selected.filter((x) => allChildIds.includes(x));
    return Api.teacher
      .saveClass({
        classId: this.id,
        name: this.name,
        students: checkedItems,
        orgId: this.orgId,
      })
      .then((res) => {
        runInAction(async () => {
          this.visible = false;
          if (!this.id) {
            await this.school?.classes.push(new Class(res.data?.saveClass));
          }
          if (this.orgId === this.store.activeSchoolId) {
            this.store.group.active(false, res.data?.saveClass?.classId);
          }
          if (this.create4challenge) {
            this.store.store.loadParticipantsAndSelectGroup(this.orgId, res.data?.saveClass?.classId);
          }
        });
      })
      .finally(() => (this.saving = false));
  }
  close() {
    this.visible = false;
  }
}
