import React, { CSSProperties } from "react";
import { observer } from "mobx-react-lite";
import "./FrBar.scss";

interface FrBarProp {
  title: string;
  info: string;
  percent: number;
  style?: CSSProperties;
  color?: string;
}

export const FrBar: React.FC<FrBarProp> = observer(({ title, info, percent, style, color }) => {
  return (
    <div className="FrBar" style={style}>
      <div className="title">{title}</div>
      <div
        className="bar"
        style={{
          borderBottomColor: color || "#718597",
          borderBottomWidth: 2 * percent,
          borderBottomStyle: "solid",
        }}
      ></div>
      <div className="info">
        {percent.toFixed(0)}% {info}
      </div>
    </div>
  );
});
