import { makeAutoObservable, runInAction, action, computed } from "mobx";
import { TeachSchoolsStore } from "../challenge/TeachSchoolsStore";

export class TeachGroupStore {
  id;
  isSchool = true;
  store: TeachSchoolsStore;

  constructor(store: TeachSchoolsStore) {
    makeAutoObservable(this, {
      store: false,
      students: computed,
    });
    this.store = store;
  }

  get group() {
    if (this.isSchool) {
      return this.store.school;
    }
    return this.store.school?.classes.find((x) => x.classId === this.id);
  }

  get name() {
    return this.isSchool ? "All School" : this.group?.name;
  }

  get students() {
    return this.group?.students;
  }

  active(isSchool, id?) {
    this.isSchool = isSchool;
    this.id = id;
    console.log("teach group store group", this.group);
    this.group?.loadStudents();
  }

  @action
  delClass() {
    return this.store.school?.delClass(this.id).then(() => {
      runInAction(() => {
        this.active(true);
      });
    });
  }
}
