import React from "react";
import "./Home.scss";
import { FrCard } from "../../components/FrCard";
import { Axis, Chart, Tooltip, Coordinate, Interval, Legend } from "bizcharts";
import { Col, Row, Typography } from "antd";
import { ChildPanel } from "./ChildPanel";
import { observer } from "mobx-react";

export const Goals = observer(({ fund }) => {
  console.log("fund", fund);
  const data = [
    {
      item: "Students Yet to Join",
      count: fund.totalStudents - fund.joinedStudents,
      percent: 100 - parseInt((fund.joinedStudents * 100.0) / fund.totalStudents + ""),
    },
    {
      item: "Participating Students",
      count: fund.joinedStudents,
      percent: parseInt((fund.joinedStudents * 100.0) / fund.totalStudents + ""),
    },
  ];
  const onChangeGoals = (index, goals) => {
    fund.onChangeGoals(index, goals);
  };

  const cols = {
    percent: {
      formatter: (val) => {
        val = val + "%";
        return val;
      },
    },
  };

  return (
    <FrCard
      title="Enrichment Goals & Status"
      style={{ minHeight: 372, marginTop: 0 }}
      bodyStyle={{
        display: "flex",
        padding: "0 16px",
        textAlign: "left",
      }}
    >
      <Row gutter={24}>
        <Col xs={24} sm={16}>
          <Typography.Paragraph>{fund.description}</Typography.Paragraph>
          <Typography.Paragraph>
            Write down your child’s activity goals first and check each item when completed.{" "}
          </Typography.Paragraph>
          {fund.goals.map((g, i) => (
            <ChildPanel key={g.id} student={g} onChange={(goals) => onChangeGoals(i, goals)}></ChildPanel>
          ))}
        </Col>
        <Col xs={24} sm={8} className="goals">
          <Chart width={240} height={180} data={data} autoFit scale={cols} style={{ float: "right" }}>
            <Coordinate type="theta" radius={0.75} />
            <Tooltip showTitle={false} />
            <Axis visible={false} />
            <Legend visible={false} />
            <Interval
              position="percent"
              adjust="stack"
              color={["item", ["#C2CFDA", "#718597"]]}
              style={{
                lineWidth: 1,
                stroke: "#fff",
              }}
            />
          </Chart>

          <div className="text" style={{ marginBottom: 12 }}>
            {fund.joinedStudents} {fund.joinedStudents > 1 ? "students" : "student"} have joined this fundraiser
          </div>
          <div className="text">
            Encourage your children to achieve these goals and invite their classmates to participate.
          </div>
        </Col>
      </Row>
    </FrCard>
  );
});
