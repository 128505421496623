import React from "react";
import { Route } from "react-router";
import { Classes } from "./class/Classes";
import "./teacher.scss";
import { Events } from "./challenge/Events";
import { Event } from "./challenge/Event";
import { observer } from "mobx-react";

export const Teach = observer(() => {
  return (
    <>
      <Route path="/teach/classes" component={Classes} />
      <Route path="/teach/events" exact component={Events} />
      <Route path="/teach/events/:id" component={Event} />
    </>
  );
});
