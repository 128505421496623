import React from "react";
import { observer } from "mobx-react";
import { Button } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import { appStore } from "../../../../store/AppStore";

export const InviteDonateBtn = observer(() => {
  const { inviteDonateStore } = appStore.parent;

  const onBtn = () => {
    inviteDonateStore.show();
  };

  return (
    <Button size="large" icon={<UserAddOutlined />} type="primary" shape="round" onClick={onBtn}>
      Invite Family {"&"} Friends
    </Button>
  );
});
