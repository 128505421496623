import React from "react";
import { Button, Popover } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./SchoolsBar.scss";

export const SchoolsBar = ({
  schools,
  curSchoolId,
  onSchool,
  btnText,
  onBtn,
  children,
  showAdd = false,
  content = "",
}: any) => {
  return (
    <div className="SchoolsBar">
      <div className="container">
        <div className="schools">
          {schools.map((x: any, i) => (
            <div
              key={x.id}
              onClick={() => x.id !== curSchoolId && onSchool(x.id)}
              className={`school ${x.id === curSchoolId ? "fcc active" : ""}`}
            >
              {x.name}
            </div>
          ))}
          {children}
        </div>

        {!!btnText && (
          <Button icon={<PlusOutlined />} danger onClick={() => onBtn && onBtn()}>
            {btnText}
          </Button>
        )}

        {/* {showAdd && <Button icon={<PlusOutlined />} type="primary" onClick={() => onBtn && onBtn()}></Button>} */}
        {showAdd && (
          <Popover placement="right" content={content} trigger="hover">
            <div className="addBtn" onClick={() => onBtn && onBtn()}></div>
          </Popover>
        )}
      </div>
    </div>
  );
};
